import React from "react";

const Footer = () => {
  return (
    <footer className="w-full">
      <div className="max-w-6xl mx-auto text-center p-5 border-t-2 border-green-500">Made by Bhaskar Shankarling © 2020</div>
    </footer>
  );
}

export default Footer;
