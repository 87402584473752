import React from "react";
import { Link } from 'gatsby'

const Header = ({ currentPage }) => {
  return (
    <nav className="w-full shadow bg-gray-100">
      <div className="flex space-x-4 max-w-6xl mx-auto h-20">
        <div className="flex flex-grow items-center p-5 font-bold text-2xl">
          <Link to="/" >
            <span className="text-green-500" >root@bhaskarshankarling.com:</span>
            <span className="text-blue-700" >{`~/${currentPage || ''}$`}&nbsp;</span>
            <span className="animate-blink">_</span>
          </Link>
        </div>
        {/* <Link className={"nav-link"} to="/blog" >
          <span>blog</span>
          <div className="w-full h-1"></div>
        </Link>
        <Link className="nav-link" to="/coding_problems" >
          <span>coding problems</span>
          <div className="w-full h-1"></div>
        </Link>
        <Link className="nav-link" to="/life" >
          <span>life</span>
          <div className="w-full h-1"></div>
        </Link>
        <Link className="nav-link" to="/bookshelf" >
          <span>bookshelf</span>
          <div className="w-full h-1"></div>
        </Link> */}
      </div>
    </nav>
  );
};

export default Header;
