import React from "react";
import Article from "./article";

const Articles = () => {
  const blogPosts = [
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    'In maximus, diam in elementum blandit, magna quam facilisis ipsum, a dignissim mi magna ac eros.',
    'Maecenas non augue mauris. Nulla lacinia accumsan leo vel ultricies.',
    'Mauris ac viverra justo. Duis pellentesque porta porttitor.',
    'Nulla facilisi. Nullam vel mi non arcu feugiat dignissim.',
    'Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum',
    'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur',
    'The passage experienced a surge in popularity during the 1960s when Letraset used it on their dry-transfer sheets',
    'Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum',
    'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur',
    'The passage experienced a surge in popularity during the 1960s when Letraset used it on their dry-transfer sheets',
  ];

  const articlesList = () => {
    const { firstColumn, secondColumn } = blogPosts.reduce((columns, post, index) => {
      if (index % 2 === 0) {
        columns.firstColumn.push(<Article>{post}</Article>)
      } else if (index % 2 === 1) {
        columns.secondColumn.push(<Article>{post}</Article>)
      }

      return columns
    }, { firstColumn: [], secondColumn: [] });

    return (
      <>
        <div>{firstColumn}</div>
        <div>{secondColumn}</div>
      </>
    )
  };

  return (
    <div className="grid grid-cols-2 w-3/5 mx-auto h-screen p-10">
      {articlesList()}
    </div>
  );
};

export default Articles;
