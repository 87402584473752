import React from "react";

const Article = ({ children }) => {
  return (
    <div className="bg-green-50 m-2 p-4 clear-none" style={{ height: 'fit-content' }}>
      <div>
        {children}
      </div>
    </div>
  );
};

export default Article;
